:root {
  --purple-dark: #2d0060;
  --purple-light: #8e2dfe;
  --purple-light-opacity-10: #8e2dfe10;
  --purple-light-opacity-20: #8e2dfe20;
  --purple-hover: #430091;
  --off-white: #f5f5f5;
  --off-white-darker: #e5e5e5;
}

body {
  margin: 0;
  background-color: #f6f0fd;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main {
  font-size: 13px;
}
